@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&family=Titillium+Web:wght@200;300;400;600;700;900&display=swap');
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
:root {
    --foreground-rgb: 0, 0, 0;
    --background-start-rgb: 214, 219, 220;
    --background-end-rgb: 255, 255, 255;
}

@media (prefers-color-scheme: dark) {
     :root {
        --foreground-rgb: 255, 255, 255;
        --background-start-rgb: 0, 0, 0;
        --background-end-rgb: 0, 0, 0;
    }
}

body {
    margin: 0;
    padding: 0;
    font-family: 'Titillium Web', 'Open Sans', sans-serif;
    line-height: 1.3;
    color: #4d4d4d;
}

.react-international-phone-input-container .react-international-phone-country-selector-button {
    background: transparent !important;
    border: none !important;
    border-radius: 0 !important;
    border-bottom: 1px solid #656565 !important;
    margin-top: -1px;
}

.react-international-phone-input-container .react-international-phone-input,
.phone-inp input {
    height: 35px !important;
    font-size: 16px !important;
    background: transparent !important;
    border: none !important;
    border-radius: 0 !important;
    border-bottom: 1px solid #656565 !important;
    box-shadow: none !important;
    outline: 0 !important;
    color: #a6a6a6 !important;
    width: calc(100% - 44px);
}

.phone-inp input {
    width: 100%;
}

@media (min-width:768px) {
    .react-international-phone-input-container .react-international-phone-input,
    .phone-inp input {
        font-size: 26px !important;
    }
}


/* Loader */

.loader-outer {
    position: fixed;
    left: 0;
    top: 0;
    width: calc(100% - 1em);
    height: calc(100% - 1em);
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
}

.cus-loader {
    display: inline-block;
    color: #00b3bb;
    font-size: 25px;
    text-indent: -9999em;
    overflow: hidden;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    position: relative;
    transform: translateZ(0);
    animation: loaderSpin 1.7s infinite ease, round 1.7s infinite ease;
}

.cus-loader-holder {
    display: inline-block;
    font-size: 25px;
    width: 1em;
    height: 1em;
}

@keyframes loaderSpin {
    0% {
        box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
    5%,
    95% {
        box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
    10%,
    59% {
        box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
    }
    20% {
        box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em;
    }
    38% {
        box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em;
    }
    100% {
        box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
}

@keyframes round {
    0% {
        transform: rotate(0deg)
    }
    100% {
        transform: rotate(360deg)
    }
}

@keyframes placeHolderShimmer {
    0% {
        background-position: -468px 0
    }
    100% {
        background-position: 468px 0
    }
}

.textLoading {
    background: #F6F6F6;
    animation-duration: 1.25s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background: #F6F6F6;
    background: linear-gradient(to right, #F6F6F6 8%, #F0F0F0 18%, #F6F6F6 33%);
    background-size: 800px 104px;
    position: relative;
}

.img-home {
    width: 80%;
    max-width: 200px;
}